import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/Layout.en'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'
import BuildDistributor from '../components/build-distributor/BuildDistributor'
import BuildDistributorItem from '../components/build-distributor/BuildDistributorItem'
import BusinessEcosystem from '../components/business-ecosystem/BusinessEcosystem'
import BusinessEcosystemItem from '../components/business-ecosystem/BusinessEcosystemIttem'
import BusinessModel from '../components/business-model/BusinessModel'
import BusinessModelItem from '../components/business-model/BusinessModelItem'
import ContactForm from '../components/contact-form/ContactForm'
import FaqAccordion from '../components/faq-accordion/FaqAccordion'
import FaqAccordionItem from '../components/faq-accordion/FaqAccordionItem'
import Partner from '../components/partner/Partner'
import PartnerItem from '../components/partner/PartnerItem'
import BizzySystem from '../components/bizzy-system/BizzySystem'
import BizzySystemItem from '../components/bizzy-system/BizzySystemItem.en'
import BizzyFeature from '../components/bizzy-feature/BizzyFeature'
import BizzyFeatureItem from '../components/bizzy-feature/BizzyFeatureItem'
import MediaBizzyPage from '../components/media/MediaBizzyPage'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const BizzyConnect = ({ data, location }) => {
	const [hash, setHash] = useState(location?.hash)
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.acfSlideBanner.slideBannerItem
	const seoData = data.wpgHeadlessQuery.seo

	return (
		<Layout location={location} logo={data.logoImage} setHash={setHash} hash={hash}>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content: seoData.opengraphImage !== null ? seoData.opengraphImage.localFile.publicURL : thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content: seoData.twitterImage !== null ? seoData.twitterImage.localFile.publicURL : thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='slide-banner'>
				<Slide>
					{sliderData.map((itemData, index) => (
						<SlideItem key={index} title={itemData.title} content={itemData.content} buttonLabel={itemData.buttonLabel} buttonLink={itemData.buttonLink} logoSrc={getImage(itemData.logoSrc.localFile)} imageSrc={getImage(itemData.imageSrc.localFile)} imageXsSrc={getImage(itemData.imageXsSrc.localFile)} withButton={itemData.withButton} withLogo={true} logoSlim={true} bgPosition='center bottom' />
					))}
				</Slide>
			</section>

			<section className='build-for-distributor'>
				<BuildDistributor title='Built by Distributor, for Distributors'>
					<BuildDistributorItem title='Single cloud based application' content='Manage all your business operations in a single cloud based application' lists={['No more messy installations', 'No more silo-ed systems, E2E connection from your brand principals to your retailers']} imageSrc={getImage(data.buildDistributor.nodes[0])} imageAlt='wpg-illustrator' />
					<BuildDistributorItem title='Manage your team<br /> with confidence' content='Get visibility & control in your team’s permissions & activities' imageSrc={getImage(data.buildDistributor.nodes[1])} imageAlt='wpg-illustrator' isReverse={true} />
					<BuildDistributorItem title='Focus on your distributions, not your system complexities' lists={['Instead of many applications from brands, 1 application across brands', 'More effective, easy to manage', 'Our team will help to connect you with brand own system']} imageSrc={getImage(data.buildDistributor.nodes[2])} imageAlt='wpg-illustrator' />
				</BuildDistributor>
			</section>

			<section className='bizzy-system'>
				<BizzySystem title="Bizzy Connect System Compares to Other's">
					<BizzySystemItem systemImages={data.bizzySystem.nodes} />
				</BizzySystem>
			</section>

			<section className='business-model'>
				<BusinessModel title='Freemium Model with Data Security in Mind' bgImageSrc={getImage(data.businessModel.nodes[0])}>
					<BusinessModelItem title='Features developed' subtitle='based on common practice distributors' imageSrc={getImage(data.businessModel.nodes[1])} imageAlt='wpg-illustrator' />
					<BusinessModelItem title='New Features every quarter' subtitle='that equips distributors to better manage/automate their operations' imageSrc={getImage(data.businessModel.nodes[2])} imageAlt='wpg-illustrator' />
				</BusinessModel>
			</section>

			<section className='business-ecosystem'>
				<BusinessEcosystem title='Tap into Warung Pintar’s Retail Universe'>
					<BusinessEcosystemItem title='Enable Sales Connectivity from Online to Offline' description='Bizzy Connect allows for seamless online and offline partnerships between distributors and brands. With our digital platform, distributors can drive offline to online adoption for better brand sales productivity and enable retailers to buy digitally or via our sales force solution to boost store sales productivity.' classImage='ecosystem-01' imageSrc={getImage(data.businessEcosystem.nodes[0])} imageXsSrc={getImage(data.businessEcosystem.nodes[4])} imageAlt='wpg-illustrator' imageXsAlt='wpg-illustrator' isColumn={true} />
					<BusinessEcosystemItem title='Connect to e-Wallet (Ovo & Linkaja)<br /> /PayLater' description='Bizzy Connect offers a state-of-the-art credit system to provide distributors with a robust method in managing credit/terms of payment.' classImage='ecosystem-02' imageSrc={getImage(data.businessEcosystem.nodes[1])} imageXsSrc={getImage(data.businessEcosystem.nodes[7])} imageAlt='wpg-illustrator' imageXsAlt='wpg-illustrator' />
					<BusinessEcosystemItem title='Bringing more <br /> Brands to you' description='Using our platform, a distributor can have enhanced visibility towards retailers and receive proper market feedback from this channel.' classImage='ecosystem-03' imageSrc={getImage(data.businessEcosystem.nodes[2])} imageXsSrc={getImage(data.businessEcosystem.nodes[5])} imageAlt='wpg-illustrator' imageXsAlt='wpg-illustrator' />
					<BusinessEcosystemItem title='Digital Merchandising with Embedded <br /> Milestones' lists={['Merchandize at a scalable rate', 'Increase effectiveness for your brand with insights of shelf share', 'Get retailers to advocate your product through milestone programs']} classImage='ecosystem-04' imageSrc={getImage(data.businessEcosystem.nodes[3])} imageXsSrc={getImage(data.businessEcosystem.nodes[6])} imageAlt='wpg-illustrator' imageXsAlt='wpg-illustrator' isColumn={true} />
				</BusinessEcosystem>
			</section>

			<section className='bizzy-connect-feature'>
				<BizzyFeature title='Features'>
					<BizzyFeatureItem
						title='Route Planning & Operations Monitoring'
						content={`
              <ul>
                <li>Delivery App helps drivers with their pick up and drop requests with Cash on Delivery integration back to Head Office</li>
                <li>Control Tower Application</li>
                <li>Bird’s eye view of entire operations made available to you for visualizing & adjustment scheduled plans via key metrics such as geography, time & delivery vehicles</li>
              </ul>
            `}
						imageSrc={getImage(data.bizzyFeatures.nodes[0])}
						imageAlt='wpg-illustrator'
					/>
					<BizzyFeatureItem title='Cash in Transit Management' content={`<p>Enables Fast Clearing of Customer Bills on the Same Day</p>`} imageSrc={getImage(data.bizzyFeatures.nodes[1])} imageAlt='wpg-illustrator' />
					<BizzyFeatureItem title='Credit Management for Retailers' content={`<p>Set up different risk profiles and manage different customer categories dynamically</p>`} imageSrc={getImage(data.bizzyFeatures.nodes[2])} imageAlt='wpg-illustrator' />
					<BizzyFeatureItem
						title='Scale your Sales Force with BFF'
						content={`
              <ul>
                <li>Monitor your Salesforce in the field</li>
                <li>Timely collection of Billing Dues</li>
                <li>Integrate customer visits with business insights to drive higher sales efficiency
                  <ul>
                    <li>Product Recommendations</li>
                    <li>Historical order details</li>
                  </ul>
                </li>
                <li>Immediate access to customer data helps Salesman make informed decisions out in the field
                  <ul>
                    <li>Remaining Credit available for customer</li>
                    <li>Last purchase date</li>
                    <li>Frequently purchased product(s)</li>
                  </ul>
                </li>
              </ul>
            `}
						imageSrc={getImage(data.bizzyFeatures.nodes[3])}
						imageAlt='wpg-illustrator'
					/>
				</BizzyFeature>
			</section>

			<section className='brand-and-distributor' id='partners'>
				<Partner title='BRAND & DISTRIBUTORS' isSecondary={true}>
					<PartnerItem
						content='Together with Coca-Cola Amatil Indonesia since Q1 2020, Warung Pintar has created a bigger numeric distribution number by digitalizing CCAI existing distribution channel in the general trade segment.'
						iconSrc={{
							image: getImage(data.cocaColaIcon),
							width: '216px',
						}}
						imageSrc={getImage(data.brandAndDistributor.nodes[2])}
						imageXsSrc={getImage(data.brandAndDistributor.nodes[7])}
						imageAlt='wpg-illustrator'
						contentColor='#E5000A'
					/>
					<PartnerItem
						content='Reckitt sales increase around 24% from month to month since late 2019. It can be seen from the growth of the monthly transactions in Warung Pintar Application that have increased 10 times over the past year, which is also the result of the steadily increasing internet penetration.'
						iconSrc={{
							image: getImage(data.reckittIcon),
							width: '179px',
						}}
						imageSrc={getImage(data.brandAndDistributor.nodes[0])}
						imageXsSrc={getImage(data.brandAndDistributor.nodes[5])}
						imageAlt='wpg-illustrator'
						contentColor='#E50072'
					/>
					<PartnerItem
						content='Since we partnered together from the beginning of June 2020, Juragan’s exposure to online trading has increased. This has led to improvement in many area. The average income of Juragan Warung Pintar has increased up to 50% with an average increase of 360 customers per month.'
						iconSrc={{
							image: getImage(data.grabMartIcon),
							width: '302px',
						}}
						imageSrc={getImage(data.brandAndDistributor.nodes[4])}
						imageXsSrc={getImage(data.brandAndDistributor.nodes[9])}
						imageAlt='wpg-illustrator'
						contentColor='#00A344'
					/>
				</Partner>
				<MediaBizzyPage images={data.mediaImages} />
			</section>

			<section className='faq' id='faq'>
				<FaqAccordion title='Faq'>
					<FaqAccordionItem
						title="What is Warung Pintar's business model, and how can our brand tap into the network?"
						content="Warung Pintar helps the brand reach the retailers in the market in the most efficient and transparent way to maximize the availability and the visibility of the brands at the retailer level.<br /><br />
            To reach this objective, every partnership requires a different approach based on their needs which is generally classified into 2 major models:<br />
            1. Trading driven model - through personally tailored campaign, Warung Pintar serves the principal in the execution partner from purchasing goods to reaching to the retailers through our network of assets/partner assets. Every trading process is monitored in our tech-enabled ecosystem.<br />
            2. Marketplace model - Warung Pintar connects the brand's partner assets in our digital platforms accessed by hundreds of thousands of merchants. Our network create a one-stop solution for retailers that is both seamless ad user-friendly.<br /><br />
            To understand further, please schedule a session with Warung Pintar's Business Development team today."
					/>
					<FaqAccordionItem
						title='On the retailer front, what is the target retailer Warung Pintar works with today? And where do you exist in today?'
						content="Warung Pintar works with any kinds of retailer in the market supplying FMCG. These retailers's challenge is inefficient and mutiple layers of supply chain in the market.We aim to simplify the whole supply chain management for retailers and provide transparent process for all stakeholders in our ecosystem.<br /><br />
            As per today, we are based in most tier-1 cities around Indonesia and expanding rapidly. For the future, we aim to partner with all FMCG-related retail formats in the market today."
					/>
					<FaqAccordionItem title="What is Warung Pintar's engagement model with retailers?" content="We take a digital first and online to offline engagement secondary approach in onboarding and retaining retailers today. All retailers make orders via our  mobile applications, but user's first onboarding, education, engagement and physical delivery are conducted offline. To retain our retailers, we currently combine both online and offline initiatives while constantly innovating." />
					<FaqAccordionItem title="How does Warung Pintar work with brands on growing into new retailers instead of brand's current retailers?" content="Warung Pintar's primary objective is to drive long term sustainable trade solving the inefficiencies found between brands and retailers today due to the long fragmented chains. As such, it is to Warung Pintar's long term detriment if we focus primarily on reaching existing stores that the brand already effectively reaches directly today (which are less problematic and less fragmented). As such, as every brand is onboarded, during the onboarding process we provide an industry leading approach, framework and conflict resolution processes that help us setup a win-win partnership with brands that ends up growing their overall business in partnership with us. Reach out to us to schedule a session to learn more!." />
					<FaqAccordionItem
						title="How is our brand's / distributor's data managed in Warung Pintar universe, and how can we ensure our competitors are not benefitting from working together with you?"
						content="We uphold the data confidentiality on our platform and have strict contractural obligations as well as company security protocols to ensure data is kept confidential to related stakeholders. On our digital platforms, each brand owner's data is segregated from one another. Each brands can only view their own data. Same case applied in the distributor level where each distributor within the platform will not have access to other distributors. <br> <br> We will take extra steps for bigger strategic accounts by providing deeper secondary level confidentiality. For example, we will assigne different account managers who could erect a 'Chinese wall' of information segregation (https://en.wikipedia.org/wiki/Chinese_wall) to ensure sensitive proprietary information is not shared between partners.'"
					/>
					<FaqAccordionItem title='What if you want to contact further?' content='Contact us! We help improve your business!' />
				</FaqAccordion>
			</section>

			<section className='contact-form'>
				<ContactForm title='STILL GOT QUESTIONS?' subtitle="Contact us and let's discuss what we can help to boost your business!" endpoint='https://getform.io/f/e403cc8a-9b83-4ef4-8af7-cd86c7a99c78' lang={true} imageSrc={getImage(data.contactBackgrounds.nodes[1])} imageXsSrc={getImage(data.contactBackgrounds.nodes[5])} maxWidth={true} />
			</section>
		</Layout>
	)
}

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "bizzy-connect-en" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfSlideBanner {
				slideBannerItem {
					buttonLabel
					buttonLink
					content
					title
					withButton
					logoSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1600)
							}
						}
					}
				}
			}
		}

		formImage: file(relativePath: { eq: "illustrations/forms/bizzy-form.png" }) {
			childImageSharp {
				gatsbyImageData(width: 1200)
			}
		}
		buildDistributor: allFile(filter: { relativeDirectory: { eq: "illustrations/build-for-distributor" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		businessModel: allFile(filter: { relativeDirectory: { eq: "illustrations/business-model" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		businessEcosystem: allFile(filter: { relativeDirectory: { eq: "illustrations/business-ecosystem" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		brandAndDistributor: allFile(filter: { relativeDirectory: { eq: "banners/partners" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1000)
				}
			}
		}
		contactBackgrounds: allFile(filter: { relativeDirectory: { eq: "illustrations/contact-forms" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1000)
				}
			}
		}
		bizzySystem: allFile(filter: { relativeDirectory: { eq: "banners/bizzy-system-en" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1200)
				}
			}
		}
		bizzyFeatures: allFile(filter: { relativeDirectory: { eq: "banners/bizzy-features" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1200)
				}
			}
		}
		mediaImages: allFile(filter: { relativeDirectory: { eq: "medias" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 200, transformOptions: { grayscale: true })
				}
			}
		}
		grabMartIcon: file(relativePath: { eq: "icons/grab-mart-icon.png" }) {
			childImageSharp {
				gatsbyImageData(width: 300)
			}
		}
		cocaColaIcon: file(relativePath: { eq: "icons/cocacola-icon.png" }) {
			childImageSharp {
				gatsbyImageData(width: 300)
			}
		}
		reckittIcon: file(relativePath: { eq: "icons/reckitt-icon.png" }) {
			childImageSharp {
				gatsbyImageData(width: 300)
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`

export default BizzyConnect
